import {mapActions, mapGetters} from 'vuex'
import validationMixin from '@/mixins/validation'
import changePassword from '../../components/change-password/index.vue'
import ImageFile from '@/components/image-file/index.vue'

export default {
    name        : 'user-students-info',
    mixins      : [validationMixin],
    data() {
        return {
            payload         : {
                birthday            : new Date().toISOString().substr(0, 10),
                activity            : '',
                gender              : '',
                avatar              : '',
                fullName            : '',
                email               : '',
                typeId              : 0,
                originCountry       : '',
                defaultCountry      : '',
                coursesLanguages    : '',
                phone               : '',
                isAuthor            : false,
                isSpeaker           : false,
                employers           : '',
                isActive            : false,
                interface           : '',
                isTester            : false,
                isNotRequiredCards  : false
            },
            validationErrors: {},
            showPassword    : false,
            valid           : false,
            openDatepicker  : false
        }
    },
    components  : {
        changePassword,
        ImageFile
    },
    computed    : {
        ...mapGetters({
            profile                 : 'profile/profile',
            countries               : 'setting/countries',
            interfaceLanguages      : 'setting/interfaceLanguages',
            coursesList             : 'setting/coursesList',
            currentStudent          : 'userStudents/currentStudent',
            updateImageLoading      : 'userStudents/updateImageLoading',
            currentStudentLoading   : 'userStudents/currentStudentLoading',
            isLoading               : 'userStudents/isLoading',
            basicCountries          : 'setting/basicCountries',
            userData                : 'userStudents/userData',
            userTypes               : 'userSetting/userTypes'
        }),
        employers() {
            return [
                {
                    name    : '0',
                    value   : '0'
                },
                {
                    name    : '1-5',
                    value   : '1-5'
                },
                {
                    name    : '5-20',
                    value   : '5-20'
                },
                {
                    name    : '20-100',
                    value   : '20-100'
                },
                {
                    name    : '100+',
                    value   : '100+'
                }
            ]
        },
        genders() {
            return [
                {
                    name    : this.$t('male'),
                    value   : 'male'
                },
                {
                    name    : this.$t('female'),
                    value   : 'female'
                }
            ]
        },
        activityList() {
            return [
                {
                    name    : this.$t('student'),
                    value   : 'student'
                },
                {
                    name    : this.$t('businessman'),
                    value   : 'businessman'
                },
                {
                    name    : this.$t('employee'),
                    value   : 'employee'
                },
                {
                    name    : this.$t('freelancer'),
                    value   : 'freelancer'
                },
                {
                    name    : this.$t('another'),
                    value   : 'another'
                }
            ]
        },
        computedDateFormatted() {
            return this.formatDate(this.payload.birthday)
        },
        defaultCountries() {
            if (this.profile) {
                const list = this.profile.countries.data.filter(e => e.type === 'access')
                
                return list.map(e => e.country.data)
            }
            
            return []
        },
        avatarObj() {
            if (!this.currentStudent) return null;

            return {
                id  : this.currentStudent.id,
                src : this.payload.avatar
            }
        },
        interfaceLanguagesNames() {
            if (!!this.interfaceLanguages && this.interfaceLanguages.length) return this.interfaceLanguages.map(item => ({
                ...item,
                name: item.locale
            }))
        },
        idCountryDefault() {
            return this.profile.countries.data.find(el => el.type === 'default').country_id
        }
    },
    created() {
        if (this.$route.name === 'user.students.personal-data') {
            console.log('id: ', this.$route.params.id);
            
            this.fetchCurrentUser({
                id: this.$route.params.id
            })
            .then(() => {
                console.log('currentStudent:');
                console.log(this.currentStudent);
                
                Object.assign(this.payload, this.currentStudent)
            })
        }
        
        if (!this.userTypes) {
            this.fetchUsersSetting()
        }
        
        if (!this.interfaceLanguages) {
            this.fetchInterface({
                id: this.idCountryDefault
            })
        }
        
        if (!this.countries) {
            this.fetchCountries()
        }
    },
    methods: {
        ...mapActions({
            fetchCountries      : 'setting/GET_COUNTRIES_LIST',
            fetchInterface      : 'setting/GET_LANGUAGE_INTERFACE_LIST',
            update              : 'userStudents/UPDATE_USER',
            updateImage         : 'userStudents/UPDATE_IMAGE',
            fetchCurrentUser    : 'userStudents/GET_LIST_ITEM',
            fetchUserData       : 'userStudents/GET_USER_DATA',
            fetchUsersSetting   : 'userSetting/GET_USERS_SETTING'
        }),
        updateImageLocal(params) {
            this.payload.avatar = params.get('src')
            
            this.submit()
        },
        formatDate(date) {
            if (!date) return null
            
            const [year, month, day] = date.split('-')
            
            return `${day}.${month}.${year}`
        },
        getFormData() {
            const data = new FormData();
            
            data.append('id'                        , this.$route.params.id)
            data.append('full_name'                 , this.payload.fullName)
            data.append('email'                     , this.payload.email)
            data.append('type_id'                   , this.payload.typeId)
            data.append('phone'                     , this.payload.phone)
            data.append('origin_country_id'         , this.payload.originCountry)
            data.append('default_country_id'        , this.payload.defaultCountry)
            data.append('is_author'                 , this.payload.isAuthor ? '1' : '0')
            data.append('is_speaker'                , this.payload.isSpeaker ? '1' : '0')
            data.append('active'                    , this.payload.isActive ? '1' : '0')
            data.append('avatar'                    , this.payload.avatar)
            data.append('gender'                    , this.payload.gender)
            data.append('birthday'                  , this.payload.birthday)
            data.append('interface_language'        , this.payload.interface)
            data.append('activity[type]'            , this.payload.activity)
            data.append('activity[employees_count]' , this.payload.employers)
            data.append('_method'                   , 'patch')
            data.append('is_tester'                 , this.payload.isTester ? '1' : '0')
            data.append('is_not_required_cards'     , this.payload.isNotRequiredCards ? '1' : '0')
            
            return data
        },
        submit() {
            this.$refs.form.validate();
            
            if (this.valid) {
                this.update(this.getFormData())
                    .then(() => {
                        this.$toasted.success(this.$t('userSuccessUpdate'))
                    })
                    .catch(error => {
                        const errors = error.response.data.validation;
                        
                        for (const i in errors) {
                            errors[i].forEach(e => {
                                this.$toasted.error(e)
                            })
                        }
                    });
            }
        },
        logInLikeUser(item, path) {
            this.fetchUserData(item).then(() => {
                window.open(
                    `${process.env.VUE_APP_ROOT_LINK}${path}?user_token=${this.userData[0].token}&user_refresh=${this.userData[1].token}&session-id=${localStorage.getItem('session-id')}`,
                    '_blank'
                )
            })
        }
    }
}
